<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'referees',
      singularName: 'referee',
      searchFields: {
        name: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
